import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Bestseller } from '../../clientComponents/bestSeller';
import CartCard from '../../clientComponents/cartCard';
import Deliverable from '../../clientComponents/myDeliverable';
import RatingInput from '../../clientComponents/ratingInput';
import SelecttTimeSlot from '../../clientComponents/selectTimeSlot/inde';
import TabbyPopup from '../../clientComponents/tabbyPopup';
import { getAccessToken } from '../../clientHelpers/localStorage';
import {
  addToCartData,
  closeAssessmentPermission,
  deleteWishlistItem,
  getSchedulesList,
  recommendedSessionList,
  reviewSessions,
  selectClientHome,
  setBookSessionToggle,
  viewSession,
  wishlist,
} from '../../clientSlices/home';
import { setRatingModalServiceOpen } from '../../clientSlices/ratingModalSlice';
import { USER_TYPE } from '../../coachConstants/genericConstants';
import { FileDownload } from '../../coachHelpers/downloadFIle';
import { decodeUserId } from '../../coachHelpers/localStorage';
import { Image } from '../../components/Image';
import NoData from '../../components/noData';
import OverlayLoader from '../../components/overlayLoader';
import { useAppSelector } from '../../hooks';
import daysCalculator from '../../utilityFunction/dayCalculator';
import './index.css';
interface IClientProps {
  categoryType?: string;
}
const ClientSession = ({ categoryType }: IClientProps) => {
  const [t] = useTranslation('common');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    if (searchParams.get('serviceType') && searchParams.get('id'))
      dispatch(
        viewSession({
          serviceId: searchParams.get('id'),
          serviceType: searchParams.get('serviceType')?.toLowerCase(),
        })
      );
    if (searchParams.get('serviceType'))
      dispatch(
        recommendedSessionList(
          searchParams.get('serviceType')?.toLowerCase() as string
        )
      );
  }, [searchParams]);
  const { sessionResults, recommenedSessions, isViewSession } =
    useSelector(selectClientHome);

  return (
    <>
      <div className="flex gap-3 ">
        <div className="lg:w-2/3 w-full lg:ml-28 lg:rtl:mr-28  my-12 shadow-lg rounded">
          <SessionDetails
            type={searchParams.get('serviceType')}
            sessionResults={sessionResults}
            isLoading={isViewSession}
          />
        </div>

        <div className="w-1/3 mt-7 lg:pr-[100px] p-5  lg:flex hidden ">
          <BookingSession sessionResults={sessionResults} />
        </div>
      </div>
      {recommenedSessions?.content?.length && userType === 'CLIENT' ? (
        <div className="lg:px-[100px] px-5">
          <div className=" pt-10">
            <span className="text-2xl font-bold text-[#153243] rtl:font-Almarai">
              {t(
                `CLIENT_SESSION_VIEW.RECOMMENDED_${(
                  searchParams.get('serviceType') as string
                )?.toUpperCase()}`
              )}
            </span>
            <Deliverable
              data={recommenedSessions?.content}
              serviceType={searchParams.get('serviceType') as string}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
const BookingSession = ({ sessionResults }: any) => {
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const [selectedTag, setSelectedTag] = useState('');
  const [tabbyToggle, setTabbyToggle] = useState(false);
  const [t] = useTranslation('common');
  const handleToggle = () => {
    setTabbyToggle(false);
  };
  const addWishList = () => {
    if (!getAccessToken()) {
      navigate('/login?user=CLIENT');
      return;
    }
    if (sessionResults?.inWishList) {
      dispatch(
        deleteWishlistItem({
          serviceType: searchParams.get('serviceType')?.slice(0, -1),
          serviceId: sessionResults.id,
        })
      );
    } else {
      dispatch(
        wishlist({
          serviceType: searchParams.get('serviceType')?.slice(0, -1),
          serviceId: sessionResults.id,
        })
      );
    }
  };
  const navigate = useNavigate();
  const addToCart = async (scheduleId: any) => {
    await dispatch(
      addToCartData({
        serviceType: searchParams.get('serviceType')?.slice(0, -1),
        serviceId: sessionResults.id,
        scheduleId: scheduleId,
        history: navigate,
      })
    );
  };
  const { scheduleList, bookSessionToggle, isBookingSlot, isLoading } =
    useSelector(selectClientHome);
  const [endOfMonthDate, setEndOfMonthDate] = useState('');
  const [startOfMonthDate, setStartOfMonthDate] = useState(
    moment(new Date()).locale('en').format('YYYY-MM-DD')
  );
  const [availableSlot, setAvailableSlot] = useState<any>([]);
  const [matchingAvailableSlot, setMatchingAvailableSlot] = useState<any>({});

  useEffect(() => {
    findEndDate(moment(new Date()).locale('en').format('YYYY-MM-DD'));
  }, []);
  useEffect(() => {
    const temp: any = {};

    temp['sessionId'] =
      searchParams.get('serviceType') === 'PACKAGES'
        ? searchParams?.get('serviceId')
        : searchParams?.get('id');
    temp['packageId'] =
      searchParams.get('serviceType') === 'PACKAGES'
        ? searchParams?.get('id')
        : 0;
    temp['startDate'] = startOfMonthDate;
    temp['endDate'] = endOfMonthDate;
    if (bookSessionToggle) dispatch(getSchedulesList(temp));
    bookSessionToggle
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [bookSessionToggle, endOfMonthDate, startOfMonthDate]);
  const findEndDate = (date: string) => {
    setStartOfMonthDate(
      (value: string) =>
        (value = moment(date).locale('en').format('YYYY-MM-DD'))
    );
    const endDate = new Date(
      Number(moment(date).locale('en').format('YYYY')),
      Number(moment(date).locale('en').format('MM')),
      0
    );
    setEndOfMonthDate(
      (value: string) =>
        (value = moment(endDate).locale('en').format('YYYY-MM-DD'))
    );
  };
  useEffect(() => {
    let temp: any = {};
    let result = scheduleList.map(({ startDateTime, date }: any) => {
      temp[moment(`${startDateTime}Z`).locale('en').format('Y-MM-DD')] = date;
      //   try {
      //     utcDatetoLocalDate(startDateTime);
      //   } catch (err) {}
      return moment(`${startDateTime}Z`).locale('en').format('Y-MM-DD');
    });
    let unique = new Set(result);
    let val = Array.from(unique);
    setMatchingAvailableSlot(temp);
    setAvailableSlot((value: any) => (value = val));
  }, [scheduleList]);
  const checkBeforeLogin = () => {
    if (!getAccessToken()) {
      navigate('/login?user=CLIENT');
      return;
    }
  };
  const handleBooking = (isCart: boolean) => {
    const payload: any = {};
    if (!getAccessToken()) {
      navigate('/login?user=CLIENT');
      return;
    }
    if (isCart) {
      document.body.style.overflow = 'unset';
      navigate('/cart');
    } else if (searchParams.get('serviceType') === 'SESSIONS')
      dispatch(setBookSessionToggle(!bookSessionToggle));
    else if (searchParams.get('serviceType') === 'PACKAGES') {
      payload['serviceId'] = searchParams.get('id');
      payload['serviceType'] = searchParams.get('serviceType')?.slice(0, -1);
      const scheduleIdList: number[] = [];
      sessionResults?.serviceShortDetailsResponses?.forEach((data: any) => {
        if (data?.serviceType === 'SESSION')
          scheduleIdList.push(data.scheduleId);
      });
      payload['scheduleId'] = scheduleIdList;
      payload['history'] = navigate;
      dispatch(addToCartData(payload));
    } else if (searchParams.get('serviceType') === 'ASSESSMENTS') {
      payload['serviceId'] = searchParams.get('id');
      payload['serviceType'] = searchParams.get('serviceType')?.slice(0, -1);
      payload['scheduleId'] = [];
      payload['history'] = navigate;
      dispatch(addToCartData(payload));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userType = localStorage.getItem('userType');
  const userId = getAccessToken() && decodeUserId(getAccessToken() as string);
  const handleSelectTag = (item: string) => {
    setSelectedTag(item);
    navigate(`/search-result?coachingCategory=${item}`);
  };
  useEffect(() => {
    if (sessionResults?.purchased && !sessionResults?.isReviewed) {
      dispatch(setRatingModalServiceOpen(true));
    } else {
      dispatch(setRatingModalServiceOpen(false));
    }
  }, [sessionResults]);
  const { isViewSession } = useSelector(selectClientHome);
  const closeAssessment = async () => {
    await dispatch(closeAssessmentPermission(searchParams.get('id')));
    await window.location.reload();
  };
  const { isRatingModalServiceOpen } = useAppSelector((state) => state.rating);
  useEffect(() => {
    const id = searchParams.get('id') || '';
    if (!id) {
      navigate('/');
    }
  }, []);
  return (
    <>
      {!isViewSession && (
        <RatingInput
          rating={'SERVICE'}
          sessionResults={sessionResults}
          show={isRatingModalServiceOpen}
        />
      )}
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      {!sessionResults?.onlyPackage && <div className="lg:shadow-lg lg:p-5 px-5 pb-5 pt-2 rounded w-full h-fit">
        <div className="flex justify-between  ">
          {!sessionResults?.purchased ? (
              <div className="text-[13px] font-normal text-[#687E8E] rtl:font-Almarai">
                {t('CLIENT_SESSION_VIEW.ENROLL_NOW')}
              </div>
          ) : null}
          <div>
            <span className="lg:flex hidden ">
              {sessionResults?.bestSeller ? <Bestseller/> : null}
            </span>
            {/* <img
              className="lg:hidden flex w-[26px] h-[26px] cursor-pointer"
              src="/assets/clientSession/session-share.svg"
              alt="img"
            /> */}
          </div>
        </div>
        <div className="flex justify-between items-center border-b-[1px] lg:border-none border-[#38383840]">
          <div className="lg:text-[45px] text-[28px] font-satoshi rtl:font-Almarai ">
            {t('SAR')} &nbsp;{sessionResults?.price}
          </div>

          <span className="sm:hidden flex">
            {sessionResults?.bestSeller ? <Bestseller/> : null}
          </span>
        </div>
        <div className="border-b border-[#38383840] pt-2 pb-3">
          <div className="flex gap-1">
            <div className="font-satoshi w-full container">
              <p>
                {t('OR_4_INTEREST_FREE_PAYMENTS_OF')} {' '}
                <span className="font-bold ">
                  {t('SAR')} {sessionResults?.price}
                </span>
                &nbsp;
                <span
                    onClick={() => setTabbyToggle(true)}
                    className=" underline cursor-pointer"
                >
                  {t('CLIENT_SESSION_VIEW.LEARN_MORE')}
                </span>
              </p>
            </div>
            <div>
              {' '}
              <img
                  className="h-12 w-16"
                  src="/assets/payment/tabbyLogo.svg"
                  alt=""
              />
            </div>
          </div>

          <TabbyPopup
              price={sessionResults?.price}
              toggle={tabbyToggle}
              toggleFunction={handleToggle}
          />
        </div>
        {userType === USER_TYPE.COACH &&
        sessionResults?.createdBy === userId ? null : (
            <div className="flex justify-between  pb-3 pt-6  gap-2">
              {!sessionResults?.purchased ? (
                  <>
                    {searchParams.get('serviceType') === 'SESSIONS' && (
                        <button
                            onClick={() => handleBooking(sessionResults?.inCart)}
                            className="bg-[#02A4EA] w-[262px] h-[52px] rounded cursor-pointer"
                        >
                    <span className="text-center text-white rtl:font-Almarai">
                      {sessionResults?.inCart
                          ? t('SLOT_BOOK.GO_TO_CART')
                          : t('CLIENT_SESSION_VIEW.BOOK_NOW')}
                    </span>
                        </button>
                    )}
                    {searchParams.get('serviceType') === 'PACKAGES' && (
                        <button
                            onClick={() => handleBooking(sessionResults?.inCart)}
                            className="bg-[#02A4EA] w-[262px] h-[52px] rounded cursor-pointer"
                        >
                    <span className="text-center text-white rtl:font-">
                      {sessionResults?.inCart
                          ? t('SLOT_BOOK.GO_TO_CART')
                          : t('SLOT_BOOK.ADD_TO_CART')}
                    </span>
                        </button>
                    )}
                    {searchParams.get('serviceType') === 'ASSESSMENTS' && (
                        <button
                            onClick={() => handleBooking(sessionResults?.inCart)}
                            className="bg-[#02A4EA] w-[262px] h-[52px] rounded cursor-pointer"
                        >
                    <span className="text-center text-white rtl:font-">
                      {sessionResults?.inCart
                          ? t('SLOT_BOOK.GO_TO_CART')
                          : t('SLOT_BOOK.ADD_TO_CART')}
                    </span>
                        </button>
                    )}
                  </>
              ) : (
                  <>
                    <button
                        className="bg-[#9EE0E6] w-[262px] h-[52px] rounded font-medium cursor-pointer rtl:font-Almarai"
                        onClick={() =>
                            navigate('/messages?id=' + sessionResults.createdBy)
                        }
                    >
                      {t('SLOT_BOOK.MESSAGE_COACH')}
                    </button>
                    {searchParams.get('serviceType') === 'ASSESSMENTS' ? (
                        <button
                            className="bg-[#02A4EA] w-[262px] h-[52px] rounded cursor-pointer"
                            onClick={closeAssessment}
                        >
                    <span className="text-center text-white rtl:font-Almarai">
                      {t('MY_CART.ASSESSMENT_REPORT_RECIEVED')}
                    </span>
                        </button>
                    ) : null}
                  </>
              )}

              {bookSessionToggle && !sessionResults?.purchased && (
                  <SelecttTimeSlot
                      data={{
                        name: sessionResults?.title,
                        duration: sessionResults?.duration,
                      }}
                      // toggleFunction={setBookSessionToggle}
                      // toggleValue={bookSessionToggle}
                      addToCart={addToCart}
                      findEndDate={findEndDate}
                      availableSlot={availableSlot}
                      coachId={sessionResults?.createdBy}
                      multiple={true}
                      timeSlotMatching={matchingAvailableSlot}
                  />
              )}
              {<OverlayLoader loader={isBookingSlot || isLoading}/>}
              {!sessionResults?.purchased && (
                  <button
                      className="border-2 border-[#38383840]  h-[52px] p-5 flex items-center rounded "
                      onClick={addWishList}
                  >
                    {/* <img
              src="/assets/clientSession/like-button.svg"
              className="self-center"
              alt=""
            ></img> */}
                    <FavoriteBorderIcon
                        sx={{color: `${sessionResults?.inWishList && 'red'}`}}
                    />
                  </button>
              )}
            </div>
        )}
        <div className="lg:border-b-2 border-b border-[#38383840]">
          <div className="text-xl font-bold pt-5 rtl:font-Almarai">
            {t('CLIENT_SESSION_VIEW.QUICK_INFO')}
          </div>

          {sessionResults?.purchased &&
          sessionResults.serviceType === 'SESSION' ? (
              <div className="flex pt-4 pb-4 ">
                <CalendarTodayIcon sx={{color: '#00a5ec'}}/>
                <div className="pl-2 text-base text-[#153243] rtl:pr-2 flex flex-col">
                  <div>
                    {moment(
                        `${sessionResults?.scheduleResponse?.startTime}Z`
                    ).locale('en').format('dddd , MMMM D')}
                  </div>
                  <div>
                    {`${moment(
                        `${sessionResults?.scheduleResponse?.startTime}Z`
                    ).locale('en').format('HH:mm')} : ${moment(
                        `${sessionResults?.scheduleResponse?.endTime}Z`
                    ).locale('en').format('HH:mm')}`}{' '}
                    &nbsp; {localStorage.getItem('timezone')}
                  </div>
                </div>
              </div>
          ) : (
              ''
          )}
          <div className="flex pt-4 pb-4 ">
            <img src="/assets/clientSession/both.svg" alt=""></img>
            <div className="pl-2 text-base text-[#153243] rtl:pr-2">
              {t(sessionResults?.intendedClientResponse?.gender)}
            </div>
          </div>
          <div className="flex pt-4 pb-4">
            <img src="/assets/clientSession/language.svg" alt=""></img>
            <div className="pl-2 text-base text-[#153243]  rtl:pr-2">
              {t(sessionResults?.language)}
            </div>
          </div>
          {sessionResults?.duration ? (
              <div className="flex pt-4 pb-4 items-start">
                <img
                    className="pt-1"
                    src="/assets/clientSession/duration.svg"
                    alt=""
                ></img>
                <div className="pl-2  text-base text-[#153243]  rtl:pr-2 rtl:font-Almarai">
                  {' '}
                  {sessionResults?.duration} {t('MINS')}
                </div>
              </div>
          ) : null}
          <div className="flex pt-4 pb-4 items-start">
            <img
                className="pt-1"
                src="/assets/clientSession/access.svg"
                alt=""
            ></img>
            <div className="pl-2  text-base text-[#153243]  rtl:pr-2">
              {t('ACCESS_ON_DESKTOP_MOBILE')}
            </div>
          </div>
        </div>
        <div className="lg:border-b-2 border-b border-[#38383840]">
          {sessionResults?.intendedClientResponse?.forWhom.length ? (
              <div className="text-xl font-bold pt-5 rtl:font-Almarai">
                {t('CLIENT_SESSION_VIEW.FOR_WHOM_FOR_THIS_SERVICE_FOR')}
              </div>
          ) : null}
          {sessionResults?.intendedClientResponse?.forWhom?.map(
              (item: any, index: number) => {
                return (
                    <div className="flex pt-4 pb-4" key={index}>
                      <img src="/assets/clientSession/rightArrow.svg" alt=""></img>
                      <div className="pl-2  text-base text-[#153243] ">{t('intentClient.' + item)}</div>
                    </div>
                );
              }
          )}
        </div>
        <div>
          <div className="text-xl font-bold  pt-5 rtl:font-Almarai">
            {t('CLIENT_SESSION_VIEW.TAGS')}
          </div>
          <div className="flex gap-10 overflow-x-auto  form-scroll pt-5">
            {sessionResults?.tags?.map((item: any, index: any) => (
                <div
                    onClick={() => handleSelectTag(item)}
                    key={index}
                    className={`flex flex-row border-[1px] p-2 h-[50%] rounded-md  ${
                        selectedTag === item
                            ? 'border-primary bg-primary '
                            : 'border-lightBlack'
                    } cursor-pointer `}
                >
                  <div
                      className={` ${
                          selectedTag === item ? 'text-white' : 'text-greyGrey'
                      }`}
                  >
                    {item}
                  </div>
                </div>
            ))}
            {sessionResults?.categoryTags?.map((item: any, index: any) => (
                <div
                    onClick={() => handleSelectTag(item)}
                    key={index}
                    className={`flex flex-row border-[1px] p-2 h-[50%] rounded-md  ${
                        selectedTag === item
                            ? 'border-primary bg-primary '
                            : 'border-lightBlack'
                    } cursor-pointer `}
                >
                  <div
                      className={` ${
                          selectedTag === item ? 'text-white' : 'text-greyGrey'
                      }`}
                  >
                    {item}
                  </div>
                </div>
            ))}
            {/* {sessionResults?.categoryTags?.map((item: any, index: any) => (
              <div
                key={index}
                onClick={() => handleSelectTag(item)}
                className="flex flex-row border-[1px] p-2 h-[50%] rounded-md border-lightBlack "
              >
                <div className="text-greyGrey">{item}ss</div>
              </div>
            ))} */}
          </div>
        </div>
      </div>}
    </>
  );
};
const SessionDetails = ({ sessionResults, type, isLoading }: any) => {
  const [tab, setTab] = useState('About');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { reviewResults } = useSelector(selectClientHome);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let payload = { reviewType: '', serviceId: '', description: '' };
    payload['reviewType'] = searchParams
      ?.get('serviceType')
      ?.slice(0, -1) as string;
    payload['serviceId'] = searchParams?.get('id') as string;
    payload['description'] = searchParams?.get('description') as string;
    dispatch(reviewSessions(payload));
    setTab('About');
  }, [searchParams]);
  useEffect(() => {
    setTab('About');
  }, [searchParams.get('id')]);
  const packageMaterialResponse = () => {
    let temp: any = { materialResponses: [] };
    sessionResults?.serviceShortDetailsResponses?.forEach((item: any) => {
      temp?.materialResponses?.push(item);
    });
    return temp;
  };
  function MyComponent(value: string) {
    let dummy: any = { materialResponses: [] };
    switch (value) {
      case 'About':
        return <About sessionResults={sessionResults} />;
      case 'Reviews':
      // return (
      //   <Reviews
      //     reviewResults={reviewResults}
      //     searchParams={searchParams}
      //     setSearchParams={setSearchParams}
      //   />
      // );
      case 'Sessions & Assessment':
        return <SessionsAndAssessment sessionResults={sessionResults} />;
      case 'Materials':
        return (
          <Materials
            materialData={
              searchParams.get('serviceType') === 'PACKAGES'
                ? packageMaterialResponse()
                : {
                    materialResponses: [
                      sessionResults?.thirdPartyProviderResponse,
                    ],
                  }
            }
            purchased={
              searchParams.get('serviceType') === 'PACKAGES'
                ? sessionResults?.purchased
                : sessionResults?.thirdPartyProviderResponse?.purchased
            }
            createdBy={sessionResults?.createdBy}
            loading={isLoading}
          />
        );
      default:
        return null;
    }
  }
  const [t] = useTranslation('common');
  return (
    <div className="lg:card-session">
      <div className="">
        <div
          className={`${
            isLoading && 'h-[165px] xl:h-[410px] w-full loader_pulse'
          }`}
        >
          {' '}
          {!isLoading && (
            <Image
              id={
                (sessionResults?.imgId as number) ||
                (sessionResults?.imageId as number)
              }
              bgImgUrl={'/assets/default-img-icon.svg'}
              bgClass={
                ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[165px] xl:h-[410px]'
              }
              propClass={'object-fill w-full rounded-t h-[165px] xl:h-[410px]'}
            />
          )}
        </div>

        <div className="flex mx-5 mt-2 md:mt-6 lg:pb-6 pb-2   lg:mx-6 border-b lg:border-none border-[#38383840] ">
          <div className=" flex w-full">
            <div
              className={` md:min-w-[55px] md:max-w-[55px] md:min-h-[55px]   md:max-h-[55px] min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]    mt-2 md:mt-[6px] rounded-full ${
                isLoading && '  loader_pulse '
              }`}
            >
              {!isLoading && (
                <Image
                  id={
                    (sessionResults?.imgId as number) ||
                    (sessionResults?.imageId as number)
                  }
                  bgImgUrl={'/assets/profile/avatar.svg'}
                  bgClass={
                    ' bg-[#F6F8F5] object-fill rounded-full flex justify-center items-center md:min-w-[55px] md:max-w-[55px] md:min-h-[55px]   md:max-h-[55px] min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]'
                  }
                  propClass={
                    'md:min-w-[55px] md:max-w-[55px] md:min-h-[55px]   md:max-h-[55px] min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] object-fill rounded-full'
                  }
                />
              )}
            </div>
            <div className="w-full flex flex-grow flex-col px-2 md:pl-4">
              <h4>
                <b className=" capitalize text-[22px] text-[#153243]">
                  {sessionResults.title}
                </b>
              </h4>
              <div className="">
                <p className="text-[#687E8E] text-13 rtl:font-Almarai">
                  {t('SLOT_BOOK.BY')}&nbsp;{' '}
                  <b
                    className="text-[#153243] text-[13px] cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/coach-profile-view?id=${sessionResults?.createdBy}`
                      )
                    }
                  >
                    {sessionResults?.coachName}
                  </b>
                  <span className="text-[13px]">
                    {' '}
                    - {sessionResults?.subTitle}
                  </span>
                </p>
                <div className="flex smmd:flex-row flex-col">
                  {/* <div className="flex items-center ">
                    <p className="text-closeRed mr-1 text-13 font-general rtl:font-Almarai font-semibold">
                      {sessionResults?.rating}
                    </p>{' '}
                    <div className="flex w-fit pb-[7px]">
                      <Rating
                        value={sessionResults?.rating}
                        classes="mt-[6px]"
                      />{' '}
                    </div>
                    <span className="text-[13px] text-gray">
                      ({sessionResults?.ratingCount}&nbsp;{' '}
                      {t('SLOT_BOOK.RATINGS')})
                    </span>
                  </div> */}
                  <div className="mt-[-5px] smmd:mt-0 ">
                    <span className="text-[#02A4EA]  text-[13px] rtl:font-Almarai ">
                      {sessionResults?.clientCount}&nbsp;{' '}
                      {t('SLOT_BOOK.ENROLLED')}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className=" hidden lg:flex w-[34px] h-[34px]">
              {/* <img
                className="sm:flex hidden cursor-pointer"
                src="/assets/clientSession/session-share.svg"
                alt="img"
              /> */}
            </div>
          </div>
        </div>
        <div className="flex lg:hidden">
          <BookingSession sessionResults={sessionResults} />
        </div>
      </div>
      <div className={`session-tab `}></div>
      <NavBar valueType={type} tab={tab} setTab={setTab} />
      {/* <Reviews reviewResults={reviewResults} /> */}

      {MyComponent(tab)}
    </div>
  );
};
const About = ({ sessionResults }: any) => {
  const [showMore, setShowMore] = useState(false);

  const [t] = useTranslation('common');

  const text = sessionResults?.description;

  return (
    <>
      <div className="px-5 pb-5 md:pt-2">
        {text ? (
          <>
            {showMore ? (
              <p
                className="mt-4 font-normal text-gray text-17 font-general rtl:font-Almarai"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            ) : (
              <p
                className={`mt-4 font-normal text-gray text-17 font-general rtl:font-Almarai `}
                style={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
          </>
        ) : (
          <p>--{t('NA')}--</p>
        )}
        {/* <div
          className="cursor-pointer "
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {text.length >= 2 &&
            (!showMore ? (
              <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-Almarai transition duration-1000 ease-in-out">
                {' '}
                {t('CLIENT_COACH_PROFILE_PAGE.SHOW_MORE')}
                <span>
                  {' '}
                  <img
                    src="/assets/clientCoachProfilePage/showMore.svg"
                    alt=""
                  />
                </span>
              </div>
            ) : (
              <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-Almarai">
                {' '}
                {t('CLIENT_COACH_PROFILE_PAGE.SHOW_LESS')}
                <span>
                  {' '}
                  <img
                    src="/assets/clientCoachProfilePage/showLess.svg"
                    alt=""
                  />
                </span>
              </div>
            ))}
        </div> */}
        <div>
          <div className="text-[#153243] text-[22px] font-bold mt-8 rtl:font-Almarai">
            {t('CLIENT_SESSION_VIEW.WHAT_WILL_CLIENT_LEARN')}
          </div>
          <Answers
            data={sessionResults?.intendedClientResponse?.whatWillLearn}
          />
        </div>
        <div>
          <div className="text-[#153243] text-[22px] font-bold mt-8 rtl:font-Almarai">
            {t('CLIENT_SESSION_VIEW.WHAT_ARE_THE_REQUIREMENTS')}
          </div>
          <Answers
            data={sessionResults?.intendedClientResponse?.requirements}
          />
        </div>
        <div>
          <div className="text-[#153243] text-[22px] font-bold mt-8 rtl:font-Almarai">
            {t('CLIENT_SESSION_VIEW.BENEFITS_FROM_THIS_SESSIONS')}
          </div>
          <Answers data={sessionResults?.intendedClientResponse?.benefits} />
        </div>
      </div>
    </>
  );
};
const SessionsAndAssessment = ({ sessionResults }: any) => {
  // localStorage.removeItem('scheduleId');
  const temp = sessionResults?.serviceShortDetailsResponses?.map(
    (item: any) => {
      return { data: item };
    }
  );
  const foundObj = temp?.find(
    (item: any) =>
      item.data.serviceType === 'SESSION' && item.data.scheduleId === 0
  );
  if (foundObj) {
    foundObj.schedule = true;
  }
  const [t] = useTranslation('common');
  return (
    <div className="mx-6 my-6">
      <div>
        <p className="text-16 font-normal font-general rtl:font-Almarai text-[#687E8E]">
          {t('SLOT_BOOK.DESCRIPTION')}
        </p>
      </div>
      <CartCard
        cardData={temp}
        category={'package_assessment'}
        purchased={sessionResults?.purchased}
      />
    </div>
  );
};
// const FileDownload = ({
//   id,
//   purchased,
// }: {
//   id: number;
//   purchased: boolean;
// }) => {
//   const dispatch = useDispatch<any>();
//   const [fileValue, setFileValue] = useState<{ [key: string]: string }>();
//   const [fileLoader, setFileLoader] = useState<boolean>();
//   let temp: any;
//   useEffect(() => {
//     const fileData = async () => {
//       try {
//         setFileLoader(true);
//         temp = await dispatch(downloadProfilePicClient(id));
//         setFileValue(temp?.payload?.data[0]);
//         setFileLoader(false);
//       } catch (err) {
//         setFileLoader(false);
//       }
//     };
//     fileData();
//   }, []);
//   const handleDownload = () => {
//     const link = document.createElement('a');
//     link.href = `data:;base64,${fileValue?.fileContent}`;
//     link.setAttribute('download', `${fileValue?.originalFileName}`);
//     document.body.appendChild(link);
//     link.click();
//   };

//   return (
//     <div
//       className=" cursor-pointer"
//       onClick={purchased ? handleDownload : undefined}
//     >
//       {fileValue?.originalFileName}
//     </div>
//   );
// };
const Materials = ({ materialData, purchased, createdBy, loading }: any) => {
  const [t] = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();
  const handleDispatch = async (id: number) => {};
  const userType = localStorage.getItem('userType');
  const userId = getAccessToken() && decodeUserId(getAccessToken() as string);

  return (
    <div className="mx-6 mt-8 mb-20 flex flex-col gap-4">
      <h1 className="font-bold font-satoshi rtl:font-Almarai text-22">
        {t(`SEARCH_RESULT_VIEW.ASSESSMENTS`)}
      </h1>
      <div className="flex flex-col gap-5">
        {!loading ? (
          materialData?.materialResponses?.length ? (
            materialData?.materialResponses?.map(
              (value: {
                materialResponses: any;
                id: number;
                serviceType: string;
              }) => (
                <div
                  className={`flex flex-col gap-4 border border-disableGray rounded-lg p-4 ${
                      !value.materialResponses ? 'hidden' : ''
                  }`}
                >
                  <h1 className="font-bold text-18 font-satoshi rtl:font-Almarai">
                    {materialData?.title}
                  </h1>
                  {value?.materialResponses?.map((item: any) => (
                    <div>
                      <div className="h-[54px] w-full  border border-disableGray rounded-lg flex items-center p-2">
                        <div
                          className={` w-full h-full flex items-center ${
                            purchased ||
                            (userType === 'COACH' && userId === createdBy)
                              ? 'cursor-pointer'
                              : 'blur-sm unselectable'
                          }`}
                        >
                          {`${
                            purchased ||
                            (userType === 'COACH' && userId === createdBy)
                              ? ''
                              : 'no text to display'
                          }`}

                          {purchased ||
                          (userType === 'COACH' && userId === createdBy) ? (
                            <a
                              href={`${item && item?.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="cursor-pointer  hover:text-primary hover:underline "
                            >
                              {item && item?.url}
                            </a>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className={`font-bold text-14 font-satoshi rtl:font-Almarai ${
                          !item.id ? 'hidden' : ''
                      }`}>
                        {t('THIRD_PARTY')}
                      </div>
                      <div
                        className={`h-[54px] w-full  border border-disableGray rounded-lg flex items-center p-2 
                        ${
                            !item.id ? 'hidden' : ''
                        }`}
                      >
                        {' '}
                        <div
                          className={` w-full ${
                            purchased ||
                            (userType === 'COACH' && userId === createdBy)
                              ? 'cursor-pointer'
                              : 'blur-sm unselectable'
                          }`}
                        >
                          {`${
                            purchased ||
                            (userType === 'COACH' && userId === createdBy)
                              ? ''
                              : 'no text to display'
                          }`}
                          {purchased ||
                          (userType === 'COACH' && userId === createdBy) ? (
                            <FileDownload id={item?.id} purchased={purchased} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            )
          ) : (
            <>
              <NoData />
            </>
          )
        ) : (
          <div className="flex justify-center items-center w-full  ">
            <div className="loader  "></div>
          </div>
        )}
      </div>
    </div>
  );
};
const ProgressBar = (progressPercentage: number) => {
  return (
    <div className="progress-bar">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={` h-full  rounded-sm bg-[#BC455E]`}
      ></div>
    </div>
  );
};
const RatingProgressBar = ({ ratings }: any) => {
  const percentageConverter = (count: number) => {
    return ratings?.totalCount ? (count / ratings?.totalCount) * 100 : 0;
  };
  const [t] = useTranslation('common');
  return (
    <>
      <div className="w-full">
        <div className="flex  w-full items-center">
          <div className="w-12 text-[13px] font-normal text-[#153243] text-start">
            5 {t('SLOT_BOOK.STAR')}
          </div>
          {ProgressBar(percentageConverter(ratings?.fiveStar))}
          <div className="pl-3 text-[13px] font-normal text-[#153243] w-[1px]">
            {percentageConverter(ratings?.fiveStar)}%
          </div>
        </div>
        <div className="flex  w-full items-center">
          <div className="w-12  text-[13px] font-normal text-[#153243]">
            4 {t('SLOT_BOOK.STAR')}
          </div>
          {ProgressBar(percentageConverter(ratings?.fourStar))}
          <div className="pl-3  text-[13px] font-normal text-[#153243] w-[1px]">
            {percentageConverter(ratings?.fourStar)}%
          </div>
        </div>
        <div className="flex  w-full items-center">
          <div className="w-12 text-[13px] font-normal text-[#153243]">
            3 {t('SLOT_BOOK.STAR')}
          </div>
          {ProgressBar(percentageConverter(ratings?.threeStar))}
          <div className="pl-3  text-[13px] font-normal text-[#153243] w-[1px]">
            {percentageConverter(ratings?.threeStar)}%
          </div>
        </div>
        <div className="flex  w-full items-center">
          <div className="w-12  text-[13px] font-normal text-[#153243]">
            2 {t('SLOT_BOOK.STAR')}
          </div>
          {ProgressBar(percentageConverter(ratings?.twoStar))}
          <div className="pl-3  text-[13px] font-normal text-[#153243] w-[1px]">
            {percentageConverter(ratings?.twoStar)}%
          </div>
        </div>
        <div className="flex  w-full items-center">
          <div className="w-12  text-[13px] font-normal text-[#153243]">
            1 {t('SLOT_BOOK.STAR')}
          </div>
          {ProgressBar(percentageConverter(ratings?.oneStar))}
          <div className="pl-3  text-[13px] font-normal text-[#153243] w-[1px]">
            {percentageConverter(ratings?.oneStar)}%
          </div>
        </div>
      </div>
    </>
  );
};
const ClientReviews = ({ reviewResults }: any) => {
  const ratingData = [
    {
      image: '/assets/clientSession/profile.svg',
      name: 'Daniel Walter Scott',
      rating: '4.5',
      days: '6',
      description:
        'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus.',
    },
    {
      image: '/assets/clientSession/profile.svg',
      name: 'Daniel Walter Scott',
      rating: '4.5',
      days: '6',
      description:
        'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus.',
    },
    {
      image: '/assets/clientSession/profile.svg',
      name: 'Daniel Walter Scott',
      rating: '4.5',
      days: '6',
      description:
        'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus.',
    },
    {
      image: '/assets/clientSession/profile.svg',
      name: 'Daniel Walter Scott',
      rating: '4.5',
      days: '6',
      description:
        'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus.',
    },
  ];
  return (
    <>
      {reviewResults.length ? (
        reviewResults?.map((item: any, index: number) => (
          <div className="flex pt-4 gap-3">
            <div className="md:min-w-[45px] min-w-[38px] max-w-[38px]  md:max-w-[45px]">
              <Image
                id={item?.profilePicId as number}
                bgImgUrl={'/assets/profile/avatar.svg'}
                bgClass={' '}
                propClass={' object-fill '}
              />
            </div>

            <div
              className={
                index === reviewResults?.length - 1
                  ? 'pb-5 '
                  : 'border-b-[1px] border-[#38383840] pb-5 pl-5'
              }
            >
              <div className="font-bold text-[#153243] text-lg">
                {item.userName}
              </div>
              <div className=" flex items-center">
                {/* <Rating value={item.rating} /> */}
                <span className="h-full text-[#687E8E] font-normal text-[13px] flex text-center pl-1">
                  {item.days}
                  <span className="pl-1">
                    <>{daysCalculator(item?.createdAt)} days ago</>
                  </span>
                </span>
              </div>

              <div className="md:text-base text-13 font-general rtl:font-Almarai font-normal text-[#153243] pt-4 break-all">
                {item.description}
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <NoData />
        </>
      )}
    </>
  );
};
const Reviews = ({ reviewResults, searchParams, setSearchParams }: any) => {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState(searchParams.get('description'));

  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) {
      const temp: { [key: string]: string } = {};
      searchParams.forEach((value: any, key: any) => {
        temp[key] = value;
      });
      temp['description'] = search;
      setSearchParams((value: any) => (value = temp));
    }
  };
  const [t] = useTranslation('common');
  const clearSearch = (e: any) => {
    setSearch((value: any) => (value = ''));
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value: any, key: any) => {
      temp[key] = value;
    });
    delete temp['description'];
    setSearchParams((value: any) => (value = temp));
  };
  return (
    <>
      <div className=" p-5">
        <div className="border-b-[1px] flex flex-col w-full border-[#38383840] p-3 lg:flex-row gap-2">
          <div className="lg:w-[15%] w-full lg:justify-center lg:items-center items-end flex lg:flex-col lg:gap-[2px] gap-2">
            <div className="text-5xl font-bold text-[#153243]">
              {reviewResults?.ratingResponse?.averageRating}
            </div>

            {/* <div className="flex pb-[6px] ">
              <Rating
                value={reviewResults?.ratingResponse?.averageRating}
                classes="mt-[6px] "
              />
            </div> */}
            <div className="text-[#687E8E] lg:text-16 text-13 font-general rtl:font-Almarai pb-[3px]">
              {reviewResults?.ratingResponse?.totalCount} Reviews
            </div>
          </div>
          <div className=" flex items-center flex-col w-full lg:w-[85%] ltr:pr-5 rtl:pl-5">
            <RatingProgressBar ratings={reviewResults?.ratingResponse} />
          </div>
        </div>
        <div>
          <div className="text-2xl font-bold pt-4">
            {' '}
            {t('CLIENT_SESSION_VIEW.FEEDBACK')}
          </div>
          {/* <SearchBox placeholder="Search job title, keywords, skills, etc..." /> */}
          <div className="w-full h-[46px] md:mt-10 md:w-[70%]  mb-2 ">
            <form className="h-full">
              <div className=" my-upload-search-box w-full h-full">
                <div className="w-[7%] h-[44px] flex items-center justify-center">
                  <button
                    type="submit"
                    onClick={(e: any) => {
                      onHandleSearch(e);
                    }}
                  >
                    <img
                      src="/assets/gridView/searchIcon.svg"
                      id={search}
                      alt=""
                    />
                  </button>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="text-gray-900 text-sm h-[42px] search-input  w-[93%] rtl:font-Almarai"
                  placeholder="Search job title, keywords, skills, etc..."
                  required
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                />
                {search && (
                  <img
                    onClick={async (e: any) => {
                      clearSearch(e);
                    }}
                    className="cursor-pointer  p-[4%]"
                    src="/assets/subscriptionPage/plusIcon.svg"
                    alt="bell-icon"
                  />
                )}
              </div>
            </form>
          </div>
          <ClientReviews
            reviewResults={reviewResults?.reviewAndRatingResponses?.content}
          />
        </div>
      </div>
    </>
  );
};
const Answers = ({ data }: any) => {
  const [t] = useTranslation('common');

  const [showMoreParagraph, setShowMoreParagraph] = useState(false);
  return (
    <>
      {!data?.length && (
        <span className=" text-light-black rtl:font-Almarai">
          --{t(`NA`)}--
        </span>
      )}
      {(showMoreParagraph ? data : data?.slice(0, 3))?.map(
        (item: any, index: number) => (
          <li
            className="list-disc p-4 font-normal text-base text-[#687E8E] rtl:font-Almarai"
            key={index}
          >
            {item}
          </li>
        )
      )}

      <div
        className="cursor-pointer "
        onClick={() => {
          setShowMoreParagraph(!showMoreParagraph);
        }}
      >
        {data &&
          data.length > 3 &&
          (!showMoreParagraph ? (
            <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-Almarai transition duration-1000 ease-in-out">
              {' '}
              {t('CLIENT_COACH_PROFILE_PAGE.SHOW_MORE')}
              <span>
                {' '}
                <img src="/assets/clientCoachProfilePage/showMore.svg" alt="" />
              </span>
            </div>
          ) : (
            <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-Almarai">
              {' '}
              {t('CLIENT_COACH_PROFILE_PAGE.SHOW_LESS')}
              <span>
                {' '}
                <img src="/assets/clientCoachProfilePage/showLess.svg" alt="" />
              </span>
            </div>
          ))}
      </div>
    </>
  );
};
const NavBar = ({ tab, setTab, valueType }: any) => {
  let tabDatas = ['About'];
  const { t } = useTranslation('common');
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [dropDownValue, setDropDownValue] = useState<string[]>([]);
  const [dropDownToggle, setDropDownToggle] = useState(false);

  let i = 1;

  while (i > 0) {
    switch (valueType) {
      case 'ASSESSMENTS': {
        tabDatas.splice(1, 0, 'Materials');
        break;
      }
      case 'PACKAGES': {
        tabDatas.splice(1, 0, 'Sessions & Assessment', 'Materials');

        break;
      }
    }
    i--;
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowSize <= 480 && valueType === 'PACKAGES') {
      let temp = tabDatas.filter(
        (value) =>
          value != tab &&
          value !=
            tabDatas[
              tabDatas.indexOf(tab)
                ? tabDatas.indexOf(tab) - 1
                : tabDatas.indexOf(tab) + 1
            ]
      );
      setDropDownValue(temp as string[]);
    }
  }, [windowSize, tab]);
  tabDatas.map((item, index) => {});
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const reference: any = useRef();
  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (e.path && e.path[0] !== reference.current) {
        dropDownToggle && setDropDownToggle(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return document.body.addEventListener('click', closeDropdown);
  }, []);

  return (
    <>
      <div
        className={`text-sm font-medium text-center text-gray-500 session-tab   flex mdMax:flex `}
      >
        <ul className="flex flex-row w-full">
          {tabDatas.map((item, index: number) => {
            return (
              <li
                key={`${index}-tab`}
                className={`mr-2 px-[15px] pt-[20px] mb-[-3px] ${
                  // item !== tab && ['PACKAGES'].includes(valueType)
                  //   ? tabDatas.indexOf(tab) + 1 === tabDatas.indexOf(item)
                  //     ? 'hidden sm:block'
                  //     : ''
                  //   : ''
                  dropDownValue.includes(item) && 'hidden'
                }`}
                onClick={() => setTab(item)}
              >
                <span
                  className={`inline-block cursor-pointer  rounded-t-lg border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300
                  ${item === tab ? 'text-black selected ' : 'text-gray'}`}
                >
                  <div
                    className={`h-full pb-[10px] rtl:font-Almarai ${
                      item === tab
                        ? 'text-black selected border-b-[3px] border-primary'
                        : 'text-gray'
                    }`}
                  >
                    {t(`CLIENT_SESSION_VIEW.${item.toUpperCase()}`)}
                  </div>
                </span>
              </li>
            );
          })}
        </ul>
        <div ref={reference} className="relative flex items-center">
          {windowSize <= 480 && valueType === 'PACKAGES' && (
            <img
              onClick={() => setDropDownToggle(!dropDownToggle)}
              className="pr-3 sm:hidden cursor-pointer"
              src="/assets/threeDots.svg"
              alt=""
            />
          )}
          {dropDownToggle && (
            <ul className="flex flex-col absolute  ltr:right-2 rtl:left-2 top-10  gap-1  shadow-custom bg-white   rounded font-satoshi rtl:font-Almarai font-semibold text-13">
              {dropDownValue.map((value) => (
                <li
                  onClick={() => (setTab(value), setDropDownToggle(false))}
                  className={` border-b border-lightBlack px-3 py-1`}
                >
                  {t(`CLIENT_SESSION_VIEW.${value?.toUpperCase()}`)}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
export default ClientSession;
