import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export interface IPrivacyData {
  data: string;
  key: string;
}
const PrivacyPolicy = () => {
  const [t] = useTranslation('common');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const privacyDataAlphabetIndex = [
    {
      data: t('PRIVACY_POLICY.ALPHABETIC_DATA_ONE'),
      key: 'A',
    },
    {
      data: t('PRIVACY_POLICY.ALPHABETIC_DATA_TWO'),
      key: 'B',
    },
    {
      data: t('PRIVACY_POLICY.ALPHABETIC_DATA_THREE'),
      key: 'C',
    },
    {
      data: t('PRIVACY_POLICY.ALPHABETIC_DATA_FOUR'),
      key: 'D',
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="text-justify sm:m-20 m-4">
        <h2 className="font-satoshi rtl:font-Almarai  font-bold text-3xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.MAIN_HEADING')}
        </h2>
        <br/>
        <h2 className="font-satoshi rtl:font-Almarai  font-bold text-3xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_INTRODUCTION_TITLE')}
        </h2>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_INTRODUCTION_DESCRIPTION')}
        </p>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-3xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS')}
        </h2>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST')}
        </h2>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_DESCRIPTION')}
        </p>
        <ol className={'list-decimal'}>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_1')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_2')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_3')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_4')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_5')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_6')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIRST_7')}</li>
        </ol>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND')}
        </h2>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_DESCRIPTION')}
        </p>
        <ol className={'list-decimal'}>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_1')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_2')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_3')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_4')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_5')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SECOND_6')}</li>
        </ol>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE')}
        </h2>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_DESCRIPTION')}
        </p>
        <ol className={'list-decimal'}>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_1')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_2')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_3')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_4')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_5')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_THREE_6')}</li>
        </ol>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR')}
        </h2>
        <ol className={'list-decimal'}>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_1')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_2')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_3')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_4')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_5')}</li>
          <li className={"mt-2"}>{t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_6')}</li>
        </ol>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FOUR_FINALIZE')}
        </p>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIVE')}
        </h2>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FIVE_DESCRIPTION')}
        </p>
        <h2 className="mt-4 font-satoshi rtl:font-Almarai  font-bold text-xl text-left rtl:text-right">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SIX')}
        </h2>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_SIX_DESCRIPTION')}
        </p>
        <p className="rtl:font-Almarai">
          {t('PRIVACY_POLICY.PRIVACY_POLICY_TERMS_SUBJECT_FINALIZE')}
        </p>
        {/*<p className="rtl:font-Almarai">{t('PRIVACY_POLICY.DESCRIPTION')}</p>*/}
        {/*<br />*/}
        {/*<p className="rtl:font-Almarai">*/}
        {/*  <span className="font-satoshi rtl:font-Almarai font-bold">*/}
        {/*    {t('PRIVACY_POLICY.SUB_HEADING')}:*/}
        {/*  </span>{' '}*/}
        {/*  {t('PRIVACY_POLICY.SUB_DESCRIPTION')}*/}
        {/*</p>*/}
        {/*<br />*/}
        {/*<p className="rtl:font-Almarai" className="font-satoshi rtl:font-Almarai font-bold">*/}
        {/*  1) {t('PRIVACY_POLICY.NUMBERIC_DATA_ONE')}*/}
        {/*</p>*/}
        {/*<br />*/}
        {/*<>*/}
        {/*  {privacyDataAlphabetIndex.map((item, index) => {*/}
        {/*    return (*/}
        {/*      <>*/}
        {/*        <p className="rtl:font-Almarai" key={index}>*/}
        {/*          {item.key} . {item.data}*/}
        {/*        </p>*/}
        {/*        <br />*/}
        {/*      </>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</>*/}
      </div>
    </>
  );
};

export default PrivacyPolicy;
