import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formDataConverter } from '../coachHelpers/utility';
import {
  AssessmentCreatePayload,
  assessmentEditPayload,
  assessmentPayloadInterface,
  assessmentSliceInterface,
} from '../coachInterfaces/assessmentInterface';
import { sessionPayloadInterface } from '../coachInterfaces/sessionInterface';
import {
  createAssessmentApi,
  editAssessmentApi,
  ImageUploadAssessment,
  saveAssessmentApi,
  saveDraftAssessmentApi,
} from '../coachServices/assessmentService';
import { showAlert } from '../slice/alert';
import { deleteDraft } from './coachHomeSlice';

const initialState: assessmentSliceInterface = {
  assessmentImagefile: {
    url: '',
    name: '',
    file: '',
    fileId: '',
  },
  assessmentFormValue: {
    title: '',
    subTitle: '',
    description: '',
    // deliveryDuration: '',
    language: '',
    category: '',
    onlyPackage: false,
    categoryTags: [],
  },
  intendedClientAssessmentFormValue: {
    gender: 'BOTH',
    whatWillLearn: ['', '', ''],
    requirements: ['', '', ''],
    forWhom: [],
    tags: [],
    benefits: ['', '', ''],
  },

  coachingPriceAssessmentFormValue: {
    price: '',
  },
  isProcessingAssessmentCreate: false,
  isProcessingAssessmentSave: false,
  thirdPartyProviderRequest: {
    title: '',
    provider: '',
    deliveryMethod: 'BOTH',
    thirdParty: [
      {
        link: '',
        file: [],
      },
    ],
  },
  fileIds: [],
  isProcessingAssessmentEdit: false,
};

export const assessmentSlice = createSlice({
  name: 'assessmentSlice',
  initialState,
  reducers: {
    setAssessmentFormData: (state, action: PayloadAction<any>) => {
      state.assessmentFormValue = action.payload;
    },

    clearAssessmentFormData: (state) => {
      state.assessmentFormValue = {
        title: '',
        subTitle: '',
        description: '',
        // deliveryDuration: '',
        language: '',
        onlyPackage: false,
        category: '',
        categoryTags: [],
      };
    },
    setIntendedClientAssessmentFormData: (
      state,
      action: PayloadAction<any>
    ) => {
      state.intendedClientAssessmentFormValue = action.payload;
    },

    clearIntendedClientAssessmentFormData: (state) => {
      state.intendedClientAssessmentFormValue = {
        gender: 'BOTH',
        whatWillLearn: ['', '', ''],
        requirements: ['', '', ''],
        forWhom: [],
        tags: [],
        benefits: ['', '', ''],
      };
    },

    setCoachingPriceAssessmentFormData: (state, action: PayloadAction<any>) => {
      state.coachingPriceAssessmentFormValue = action.payload;
    },

    clearCoachingPriceAssessmentFormData: (state) => {
      state.coachingPriceAssessmentFormValue = {
        price: '',
      };
    },
    setAssessmentImageFileFormData: (state, action) => {
      state.assessmentImagefile.url = action.payload.url;
      state.assessmentImagefile.name = action.payload.name;
      state.assessmentImagefile.file = action.payload.file;
      state.assessmentImagefile.fileId = action.payload.fileId;
    },
    clearAssessmentImageFileFormData: (state) => {
      state.assessmentImagefile.url = '';
      state.assessmentImagefile.name = '';
      state.assessmentImagefile.file = '';
      state.assessmentImagefile.fileId = '';
    },
    setThirdPartyFileIds: (state, action) => {
      state.fileIds = action.payload;
    },
    clearThirdPartyFileIds: (state) => {
      state.fileIds = [];
    },
    setThirdPartyAssessmentFormData: (state, action: PayloadAction<any>) => {
      state.thirdPartyProviderRequest = action.payload;
    },

    clearThirdPartyAssessmentFormData: (state) => {
      state.thirdPartyProviderRequest = {
        title: '',
        provider: '',
        deliveryMethod: 'BOTH',

        thirdParty: [
          {
            link: '',
            file: [],
          },
        ],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editCreateAssessment.pending, (state) => {
        state.isProcessingAssessmentEdit = true;
      })
      .addCase(editCreateAssessment.fulfilled, (state, action) => {
        state.isProcessingAssessmentEdit = false;
      })
      .addCase(editCreateAssessment.rejected, (state, action) => {
        state.isProcessingAssessmentEdit = false;
      })
      .addCase(postCreateAssessment.pending, (state) => {
        state.isProcessingAssessmentCreate = true;
      })
      .addCase(postCreateAssessment.fulfilled, (state, action) => {
        state.isProcessingAssessmentCreate = false;
      })
      .addCase(postCreateAssessment.rejected, (state, action) => {
        state.isProcessingAssessmentCreate = false;
      })
      .addCase(saveCreateAssessment.pending, (state) => {
        state.isProcessingAssessmentSave = true;
      })
      .addCase(saveCreateAssessment.fulfilled, (state, action) => {
        state.isProcessingAssessmentSave = false;
      })
      .addCase(saveCreateAssessment.rejected, (state, action) => {
        state.isProcessingAssessmentSave = false;
      })
      .addCase(uploadAssessmentImage.pending, (state) => {})
      .addCase(uploadAssessmentImage.fulfilled, (state, action) => {})
      .addCase(uploadAssessmentImage.rejected, (state, action) => {});
  },
});
export const postCreateAssessment = createAsyncThunk(
  'assessment/create',
  async (
    data: { payload: AssessmentCreatePayload; navigate: any },
    thunkAPI
  ) => {
    const { assessmentInfo } = thunkAPI.getState() as {
      assessmentInfo: assessmentSliceInterface;
    };
    try {
      const photoIdArray: number[] = [];

      let photoId: string = '';
      if (assessmentInfo.assessmentImagefile.fileId !== '') {
        photoId = assessmentInfo.assessmentImagefile.fileId;
      } else if (assessmentInfo.assessmentImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadAssessmentImage({
            file: assessmentInfo.assessmentImagefile.file[0],
            fileType: 'ASSESSMENT_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      if (assessmentInfo?.fileIds?.length) {
        assessmentInfo.fileIds.map((item: number) => {
          photoIdArray.push(item);
        });
      }
      if (data.payload?.payload?.thirdPartyProviderRequest?.file?.length) {
        await Promise.all(
          data.payload?.payload?.thirdPartyProviderRequest?.file.map(
            async (item: any, index: number) => {
              const imageData = await thunkAPI.dispatch(
                uploadAssessmentImage({
                  file: item[0],
                  fileType: 'THIRD_PARTY_PROVIDER_DOCUMENT',
                })
              );

              photoIdArray.push(imageData.payload.data[0]?.id);
            }
          )
        );
      }

      const payloadData = {
        ...data.payload?.payload,
        thirdPartyProviderRequest: {
          title: data.payload?.payload?.thirdPartyProviderRequest?.title,
          provider: data.payload?.payload?.thirdPartyProviderRequest?.provider,
          termsOfServiceUrl:
            data.payload?.payload?.thirdPartyProviderRequest?.termsOfServiceUrl,
          deliveryMethod:
            data.payload?.payload?.thirdPartyProviderRequest?.deliveryMethod,
          fileIds: photoIdArray,
        },
        imageId: photoId,
      };

      const response = await createAssessmentApi(payloadData);

      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      if (data.payload.draft === 'DRAFT')
        thunkAPI.dispatch(deleteDraft(data.payload.id));

      thunkAPI.dispatch(clearAssessmentFormData());
      thunkAPI.dispatch(clearIntendedClientAssessmentFormData());
      thunkAPI.dispatch(clearCoachingPriceAssessmentFormData());
      thunkAPI.dispatch(clearThirdPartyAssessmentFormData());
      thunkAPI.dispatch(clearThirdPartyFileIds());
      thunkAPI.dispatch(
        setAssessmentImageFileFormData({
          url: '',
          name: '',
          file: '',
          fileId: '',
        })
      );
      data.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const editCreateAssessment = createAsyncThunk(
  'assessment/edit',
  async (payload: assessmentEditPayload, thunkAPI) => {
    const { assessmentInfo } = thunkAPI.getState() as {
      assessmentInfo: assessmentSliceInterface;
    };
    try {
      const photoIdArray: number[] = [];

      let photoId: string = '';
      if (assessmentInfo.assessmentImagefile.fileId !== '') {
        photoId = assessmentInfo.assessmentImagefile.fileId;
      } else if (assessmentInfo.assessmentImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadAssessmentImage({
            file: assessmentInfo.assessmentImagefile.file[0],
            fileType: 'ASSESSMENT_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      if (assessmentInfo?.fileIds?.length) {
        assessmentInfo.fileIds.map((item: number) => {
          photoIdArray.push(item);
        });
      }
      if (payload?.data?.thirdPartyProviderRequest?.file?.length) {
        await Promise.all(
          payload?.data?.thirdPartyProviderRequest?.file.map(
            async (item: any, index: number) => {
              const imageData = await thunkAPI.dispatch(
                uploadAssessmentImage({
                  file: item[0],
                  fileType: 'THIRD_PARTY_PROVIDER_DOCUMENT',
                })
              );

              photoIdArray.push(imageData.payload.data[0]?.id);
            }
          )
        );
      }
      const payloadData = {
        ...payload.data,
        thirdPartyProviderRequest: {
          title: payload.data?.thirdPartyProviderRequest?.title,
          provider: payload.data?.thirdPartyProviderRequest?.provider,
          termsOfServiceUrl:
            payload.data?.thirdPartyProviderRequest?.termsOfServiceUrl,
          deliveryMethod:
            payload.data?.thirdPartyProviderRequest?.deliveryMethod,
          fileIds: photoIdArray,
        },
        imageId: photoId,
      };

      const response = await editAssessmentApi(
        payloadData as sessionPayloadInterface,
        payload.id
      );

      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      payload.navigate('/coach/my-uploads?filter=APPROVED');
      thunkAPI.dispatch(clearAssessmentFormData());
      thunkAPI.dispatch(clearIntendedClientAssessmentFormData());
      thunkAPI.dispatch(clearCoachingPriceAssessmentFormData());
      thunkAPI.dispatch(clearThirdPartyAssessmentFormData());
      thunkAPI.dispatch(clearThirdPartyFileIds());
      thunkAPI.dispatch(
        setAssessmentImageFileFormData({
          url: '',
          name: '',
          file: '',
          fileId: '',
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveCreateAssessment = createAsyncThunk(
  'assessment/save',
  async (
    data: { payload: assessmentPayloadInterface; navigate: any },
    thunkAPI
  ) => {
    const { assessmentInfo } = thunkAPI.getState() as {
      assessmentInfo: assessmentSliceInterface;
    };
    try {
      const photoIdArray: number[] = [];

      let photoId: string = '';
      if (assessmentInfo.assessmentImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadAssessmentImage({
            file: assessmentInfo.assessmentImagefile.file[0],
            fileType: 'ASSESSMENT_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      if (data.payload?.thirdPartyProviderRequest?.file?.length) {
        await Promise.all(
          data.payload?.thirdPartyProviderRequest?.file.map(
            async (item: any, index: number) => {
              const imageData = await thunkAPI.dispatch(
                uploadAssessmentImage({
                  file: item[0],
                  fileType: 'THIRD_PARTY_PROVIDER_DOCUMENT',
                })
              );

              photoIdArray.push(imageData.payload.data[0]?.id);
            }
          )
        );
      }
      const payloadData = {
        ...data.payload,
        thirdPartyProviderRequest: {
          title: data.payload.thirdPartyProviderRequest?.title,
          provider: data.payload.thirdPartyProviderRequest?.provider,
          termsOfServiceUrl:
            data.payload.thirdPartyProviderRequest?.termsOfServiceUrl,
          deliveryMethod:
            data.payload.thirdPartyProviderRequest?.deliveryMethod,
          fileIds: photoIdArray,
        },
        imageId: photoId,
      };
      const response = await saveAssessmentApi({
        request: { ...payloadData },
        draftType: 'ASSESSMENT',
      });

      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );

      thunkAPI.dispatch(clearAssessmentFormData());
      thunkAPI.dispatch(clearIntendedClientAssessmentFormData());
      thunkAPI.dispatch(clearCoachingPriceAssessmentFormData());
      thunkAPI.dispatch(clearThirdPartyAssessmentFormData());
      data.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveDraftAssessment = createAsyncThunk(
  'assessment/save',
  async (
    payload: { payload: assessmentPayloadInterface; id: string; navigate: any },
    thunkAPI
  ) => {
    const { assessmentInfo } = thunkAPI.getState() as {
      assessmentInfo: assessmentSliceInterface;
    };
    try {
      const photoIdArray: number[] = [];

      let photoId: string = '';
      if (assessmentInfo.assessmentImagefile.fileId !== '') {
        photoId = assessmentInfo.assessmentImagefile.fileId;
      } else if (assessmentInfo.assessmentImagefile.file !== '') {
        const imageData = await thunkAPI.dispatch(
          uploadAssessmentImage({
            file: assessmentInfo.assessmentImagefile.file[0],
            fileType: 'ASSESSMENT_IMAGE',
          })
        );

        photoId = imageData.payload?.data[0]?.id;
      }
      if (assessmentInfo?.fileIds?.length) {
        assessmentInfo.fileIds.map((item: number) => {
          photoIdArray.push(item);
        });
      }
      if (payload?.payload?.thirdPartyProviderRequest?.file?.length) {
        await Promise.all(
          payload?.payload?.thirdPartyProviderRequest?.file.map(
            async (item: any, index: number) => {
              const imageData = await thunkAPI.dispatch(
                uploadAssessmentImage({
                  file: item[0],
                  fileType: 'THIRD_PARTY_PROVIDER_DOCUMENT',
                })
              );

              photoIdArray.push(imageData.payload.data[0]?.id);
            }
          )
        );
      }
      const payloadData = {
        ...payload.payload,
        thirdPartyProviderRequest: {
          title: payload?.payload?.thirdPartyProviderRequest?.title,
          provider: payload.payload?.thirdPartyProviderRequest?.provider,
          termsOfServiceUrl:
            payload.payload?.thirdPartyProviderRequest?.termsOfServiceUrl,
          deliveryMethod:
            payload.payload?.thirdPartyProviderRequest?.deliveryMethod,
          fileIds: photoIdArray,
        },
        imageId: photoId,
      };
      const response = await saveDraftAssessmentApi(
        {
          request: { ...payloadData },
          draftType: 'ASSESSMENT',
        },
        payload.id
      );

      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );

      thunkAPI.dispatch(clearAssessmentFormData());
      thunkAPI.dispatch(clearIntendedClientAssessmentFormData());
      thunkAPI.dispatch(clearCoachingPriceAssessmentFormData());
      thunkAPI.dispatch(clearThirdPartyAssessmentFormData());
      payload.navigate('/coach/create-session');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const oneMSizeByByte = 1024 * 1024;

export const uploadAssessmentImage = createAsyncThunk(
  'image/upload',
  async (payload: { fileType: string; file: any }, thunkAPI) => {
    try {
      if(payload.file.size <= oneMSizeByByte){
        const response = await ImageUploadAssessment(
            formDataConverter(payload.file, payload.fileType)
        );

        return response;
      } else {
        thunkAPI.dispatch(
            showAlert({ alertType: 'error', message: 'File size should be less than 1 MB' })
        );
      }
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({ alertType: 'error', message: err.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const {
  setAssessmentFormData,
  clearAssessmentFormData,
  setIntendedClientAssessmentFormData,
  clearIntendedClientAssessmentFormData,
  setCoachingPriceAssessmentFormData,
  clearCoachingPriceAssessmentFormData,
  setAssessmentImageFileFormData,
  clearAssessmentImageFileFormData,
  setThirdPartyAssessmentFormData,
  clearThirdPartyAssessmentFormData,
  setThirdPartyFileIds,
  clearThirdPartyFileIds,
} = assessmentSlice.actions;
export const assessmentInfo = (state: any) => state.assessmentInfo;
export const assessmentInfoReducer = assessmentSlice.reducer;
